@import src/assets/styles/variables.sass

.ag-advanced-filter-header
  display: none !important
  height: 0
  headerHeight: 0
  color: #f5f5f5


.ag-cell-wrapper
  align-items: stretch
  justify-content: stretch
  width: 100%
  height: 100%

.ag-theme-balham
  --ag-cell-horizontal-border: solid var(--ag-row-border-color)
  --ag-header-column-separator-height: 100%

.ag-grid-table
  .ag-root-wrapper
    border: none

  .historyBackgroundColor
    background-color: $yellow-0

  .custom-color-cell
    height: 80%
    overflow: hidden
    text-overflow: ellipsis
    padding: 2px 8px
    border-radius: 10px

  .shared-status-color-cell
    display: flex
    align-items: center
    height: 80%
    width: 100%
    padding: 0 6px
    border-radius: 10px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    justify-content: left
    span
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      flex-grow: 1

  .shared-status-cell-renderer
    justify-content: flex-start
    align-items: center
    max-height: 25px
    height: 100%
    width: 100%
    display: flex

  .shared-button-text
    flex-grow: 1
    text-align: left
    margin-left: 3px

  .italic-text
    color: $gray-4
    font-style: italic

  .p-dropdown
    height: 27px
    height: -moz-available
    height: -webkit-fill-available
    height: stretch
    border-radius: 0
    .p-dropdown-label
      margin-top: -10px

  .p-autocomplete
    height: 27px
    height: -moz-available
    height: -webkit-fill-available
    height: stretch

    .p-inputtext
      border-radius: 0

    .pi-times:before
      position: absolute
      right: -14px
      top: 0

    .p-button
      border-radius: 0
      width: 24px
      height: -moz-available !important
      height: -webkit-fill-available !important
      height: stretch !important

  .p-calendar .p-inputtext
    height: 27px
    font-size: 12px
