/* Helvetica Neue Black Italic */
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaBlkIt.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: italic;
  }
  
  /* Helvetica Neue Bold */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
  }
  
  /* Helvetica Neue Bold Italic */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueBoldItalic.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: italic;
  }
  
  /* Helvetica Neue Condensed Black */
  @font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('/assets/fonts/HelveticaNeueCondensedBlack.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: normal;
  }
  
  /* Helvetica Neue Condensed Bold */
  @font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('/assets/fonts/HelveticaNeueCondensedBold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
  }
  
  /* Helvetica Neue Italic */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueItalic.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: italic;
  }
  
  /* Helvetica Neue Light */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueLight.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
  }
  
  /* Helvetica Neue Light Italic */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueLightItalic.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: italic;
  }
  
  /* Helvetica Neue Medium */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueMedium.ttf') format('truetype');
    font-weight: 500; /* Medium */
    font-style: normal;
  }
  
  /* Helvetica Neue Ultra Light */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueUltraLight.ttf') format('truetype');
    font-weight: 200; /* Ultra Light */
    font-style: normal;
  }
  
  /* Helvetica Neue Ultra Light Italic */
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeueUltraLightItal.ttf') format('truetype');
    font-weight: 200; /* Ultra Light */
    font-style: italic;
  }
  