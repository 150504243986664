@import src/assets/styles/variables.sass

.p-dropdown .p-dropdown-label
  margin-top: -4px

.auth-container

  .logo
    margin: 80px 0
    display: flex
    justify-content: center
    height: 46px

  .title
    font-weight: 600
    font-size: 26px
    margin-bottom: 16px

  .subtitle
    margin-top: 26px
    font-weight: 600
    font-size: 14px

    &--grey
      color: $gray-7
      font-weight: 400

  .info
    margin-top: 26px

  .textfield
    margin-top: 20px

    &__label
      font-weight: 600
      font-size: 14px
      margin-bottom: 4px

    &--error
      border-color: $warning-color

    &__error-message
      color: $warning-color
      font-weight: 600
      font-size: 14px
      margin-left: 10px
      margin-top: 10px

    &__inline
      display: flex
      justify-content: space-between

  & button
    width: 100%
    margin-top: 24px

  .disclaimer
    display: flex
    justify-content: center
    flex-wrap: wrap
    width: 90%
    gap: 2px
    text-align: center
    color: #7A7A7A
    font-size: 12px
    margin-top: 34px

    &--grey
      color: #7A7A7A

    &--recover
      font-size: 16px

  .in-line
    display: inline-flex
    gap: 4px

.textfield
  margin-top: 16px

  &__label
    font-weight: 600
    font-size: 14px
    margin-bottom: 4px

  &--error
    border-color: $warning-color

  &__error-message
    color: $warning-color
    font-weight: 600

  &__inline
    display: flex
    justify-content: space-between

.loader
  display: flex
  align-items: center
  justify-content: center
  min-height: 100px
  width: 100%
  height: 100%

.form-controls
  width: 100%
  border: 1px solid $gray-9
  border-radius: 0.375rem
  padding: 0.375rem 0.75rem
  height: 32px
  &:hover
    border-color: #2196F3
  &:focus,&:active
    border-color: $blue-main
    outline: none

.link
  text-decoration: underline
  color: #0d6efd
  cursor: pointer
  font-size: 12px

  &--no-underline
    text-decoration: none

.dropdown-not-selected-option
  color: #C5C5C5

.icon-inside-input
  position: relative
  & i
    position: absolute
    right: 0
    top: 0
    padding: 5px 10px
    color: $gray-9
