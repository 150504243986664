$phone-only: 599px
$tablet-portrait-up: 600px
$tablet-landscape-up: 900px
$desktop-up: 1200px
$big-desktop-up: 1800px

$phone-only-table-padding: 360px
$tablet-table-padding: 450px
$desktop-up-table-padding: 340px
$big-desktop-up-table-padding: 320px

$warning-color: #FF0000
$notification-color: #ED1C1C
$blue-main: #3C7DF4
$blue-main-border: #556ab5
$blue-hover: #2f55e0
$black: #000000
$white: #FFFFFF
$gray-0: #f5f5f5
$gray-1: #f1f1f1
$gray-2: #EBEBEB
$gray-3: #CACACA
$gray-4: #969696
$gray-5: #838383
$gray-6: #616161
$gray-7: #494A4B
$gray-8: #f8f8f8
$gray-9: #ced4da
$green-0: #C6FAB8
$green-1: #00AC00
$blue-0: #CDD6FF
$blue-1: #5059FE
$blue-2: #073DFC
$orange-0: #FFEBCD
$orange-1: #FF9900
$cyan-0: #69DED2
$yellow-0: #FBFF4A
