@import src/assets/styles/variables.sass

.button-primary
  background: $blue-main!important
  &:hover
    background: #3156d7 !important
  &:active
    background: #2c4bbb !important

//overwrite button colors ( the order is important ! the classes below overwrite the ones here that are set globaly )

.p-button
  background: $blue-main
  border: 1px solid $blue-main

.p-button:enabled:hover
  background: $blue-main
  border: 1px solid $blue-main

.p-checkbox .p-checkbox-box.p-highlight , .p-checkbox .p-checkbox-box.p-highlight.p-focus
  background: $blue-main
  border-color: $blue-main

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover
  border-color: $blue-main

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover
  border-color: $blue-main
  background: $blue-main

.p-radiobutton .p-radiobutton-box.p-highlight
  background: $blue-main
  border-color: $white
  outline: 1px solid $blue-main

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover
  border-color: $blue-main

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus , .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus
  box-shadow: none

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon
  background-color: $blue-main

.p-radiobutton .p-radiobutton-box:not(.p-disabled):hover
  box-shadow: none
  border-color: $blue-main

.p-radiobutton .p-radiobutton-box.p-disabled:hover
  border-color: $blue-main

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover
  background: $blue-main
  border-color: $blue-main

.p-checkbox .p-checkbox-box:not(.p-disabled):hover
  border-color: $blue-main

.p-inputtext:enabled:focus
  box-shadow: none
  border-color: $blue-main

.p-dropdown:not(.p-disabled).p-focus
  box-shadow: none
  border-color: $blue-main

.p-dropdown.p-disabled  .p-dropdown-trigger
  display: none

.p-dropdown:hover
  border-color: $blue-main

.p-dropdown .p-dropdown-label.p-placeholder
  color: #C5C5C5

//sign-up css

.container-select-button
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center
  gap: 12px
  margin-top: 38px
  min-height: 112px
  div
    width: 190px
    height: 50px
    border: 0.25px solid $gray-9!important
    border-radius: 5px!important
    font-size: 14px
  p-button
    width: 190px

.p-selectbutton .p-button.p-highlight
  background-color: rgb(56 96 237 / 70%)

.p-selectbutton .p-button.p-highlight:hover
  background-color: rgb(56 96 237 / 73%)

.p-button:focus, .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus
  box-shadow: none

//tab menu

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link
  height: 20px
  font-size: 12px
  white-space: nowrap
  padding: 1rem 0

.p-field-checkbox
  display: flex
  gap: 10px

.p-dropdown,.p-autocomplete
  width: 100%
  border-radius: 0.375rem
  height: 32px

//input Switch
.p-inputswitch
  height: 16px
  width: 25px
  margin-top: 5px

.p-inputswitch .p-inputswitch-slider:before
  height: 10px
  width: 10px
  top: 50%
  margin-top: -5px
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before
  transform: translateX(8px)
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider
  background-color: #5FC64C !important

.p-inputswitch.p-focus .p-inputswitch-slider
  box-shadow: none

  //Sort filter descending/ascending button select
.filters__container-sort-form-content-option
  .p-selectbutton.filters-select
    height: 25px
    margin: auto 0 !important
.filters__container-sort-form-content-option
  .p-button-label
    font-size: 11px

.filters__container-sort-form-content-option
  .p-selectbutton .p-button
    background-color: #F2F2F2 !important
    height: 100%
    width: 70px
    border-radius: 5px
    border: none

  .p-selectbutton .p-button.p-highlight
    background-color: #B2B2B2 !important
    border: none

//Dropdowns in filters for filter fields
.filters__container-filter-form-content-group, .filters__container-sort, .filters__container-filter-form-content-left, .filters__container-templates-item-row
  .p-dropdown
    width: 120px
    height: 27px
    padding: 0

  .p-dropdown.filter-dropdowns
    width: 144px
    margin-right: 2px

  .p-dropdown .p-dropdown-label.p-placeholder
    height: 100%
    font-size: 12px
    color: $gray-9

  .p-dropdown .p-inputtext
    font-size: 12px
    color: $gray-6

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item
    font-size: 12px
    color: $gray-6

  .p-dropdown-panel .p-dropdown-items
    padding: 0

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight
    background-color: $gray-2
    color: $gray-6
  .p-dropdown .p-dropdown-trigger span
    font-size: 10px

  .p-dropdown:not(.p-disabled).p-focus
    box-shadow: none
    border: 0.5px solid #7E7E7E
  .p-dropdown:not(.p-disabled):hover
    border: 0.5px solid #7E7E7E

.filters__container-filter-form-content-left
  .p-dropdown.filter-small
    width: 70px

.filters__container-templates-item-row
  .p-dropdown
    height: 22px
    width: 134px

  .p-dropdown .p-dropdown-label
    margin-top: -5px

  .p-dropdown .p-dropdown-label.p-placeholder
    font-size: 11px

.loader
  .p-progress-spinner-circle
    stroke: $blue-main !important

.loader-overlay
  position: absolute
  top: 50%
  z-index: 1
  left: 50%

.dialog-mask
  .p-dialog .p-dialog-header-icons
    display: none

.small-dialog
  .p-dialog .p-dialog-content
    padding: 0 3rem

.p-dialog .p-dialog-content
  padding: 0 1.5rem

.p-autocomplete .p-inputtext
  color: #212529
  border-radius: 0.375rem

//table
.p-datatable .p-datatable-thead > tr > th
  font-weight: bold
  font-size: 12px
  padding: 0.4rem 0.5rem

  span:not(.wrap)
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

.editable-table.p-datatable .p-datatable-tbody > tr > td
  padding: 0
  font-size: 12px





.editable-table--jobs.p-datatable .p-datatable-tbody > tr > td
  border: 1px solid #e9ecef
  padding: 2px 0px 2px 4px

.p-datatable .p-datatable-tbody > tr > td
  padding: 0.3rem 0.5rem
  height: 30px

.p-datatable-table
  border: 1px solid #e9ecef


.supplierscredentials .p-datatable-table,
.supplierscredentials .p-datatable
  border: 0px !important
  border-image-width: 0px

.supplierscredentials .p-scroller
  overflow: hidden

.supplierscredentials .p-scroller:hover
    overflow: auto


.p-datatable-resizable>.p-datatable-wrapper, .p-datatable
  height: 100%

.p-datatable-scrollable .p-frozen-column
  z-index: 1

.p-element .p-highlight-contextmenu
  outline: none !important

.p-datatable .p-datatable-tbody > tr:focus
  outline: none !important

.editable-table
  .p-datatable-scrollable-table>.p-datatable-thead
    top: -1px
    z-index: 2

  .p-inputtext:not(.dialog__content-input-text)
    padding: 4px
    font-size: 12px
    width: 100%
    height: 20px
    max-width: 140px

  .p-dropdown .p-dropdown-clear-icon
    font-size: 8px
    right: 25px
    margin-top: -4px

  .p-dropdown.p-dropdown-clearable .p-dropdown-label
    padding-right: 2px

  .pi
    font-size: 10px

  .p-dropdown
    height: 22px
    max-width: 140px

.nested-table
  margin: 8px 32px

.custom-dropdown-panel .p-dropdown-items .p-dropdown-item
  font-size: 12px

.row-border-right
  margin: -9px
  margin-top: -9px
  padding: 9px
  border-right: 3px solid #e9ecef
  &--frozen-column
    border-right: 3px solid #d7d7d7

  //add an order modal dropdown
.document__data-type .dropdown
  border: 0
  &:not(.p-disabled).p-focus
    border: 0
    box-shadow: none
  &::not(.p-disabled):hover
    border: 0

  //add an order datepicker
.document__data-date .datepicker
  .p-inputtext
    border: 0
    padding: 0 8px
    font-size: 14px

    &:enabled:focus,&:enabled:hover
      border: 0
      box-shadow: none

  //badge home page
.p-overlay-badge .p-badge
  right: -50px !important
  width: 100px

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa

  .svg-inline--fa fa-rectangle-list
    color: #FF1AA3

.dialog
  &__header
    display: flex
    font-weight: 600
    font-size: 18px
    align-items: center

  &-close
    border: 0
    background-color: $white
    border-radius: 50%
    padding: 1px 6px

    &:hover
      background-color: $gray-0

    .bi-x-lg
      font-size: 18px

//confirm dialog overwrite
.confirm-reject-button
  border: 1px solid $gray-5
  color: $gray-7
  font-weight: 600
  background-color: $white
  padding: 8px 30px
  &:hover
    background-color: $gray-5 !important
    border: 1px solid $gray-5 !important

.confirm-accept-button
  border: 1px solid #eaeaea
  color: $gray-7
  font-weight: 600
  background-color: #eaeaea
  padding: 8px 30px
  &:hover
    background-color: $gray-5 !important
    border: 1px solid $gray-5 !important

.p-dialog.p-confirm-dialog .p-confirm-dialog-message
  margin: 0px 0px 30px 15px
  color: #888888

.menu-divider
  .p-divider.p-divider-horizontal
    margin: 8px 0
    width: 110%

.modal-divider
  .p-divider.p-divider-horizontal
    margin: 0

  &--dashed
    .p-divider.p-divider-horizontal
      margin-top: 12px

.templates-dropdown
  margin: auto 0
  .p-dropdown
    font-size: calc(8px + 0.3vw)
    height: 3%
    width: 75%
    text-align: start

  .p-dropdown .p-dropdown-label.p-placeholder
    font-size: calc(8px + 0.3vw)

  .p-dropdown .p-dropdown-label
    font-size: calc(8px + 0.3vw)

  .p-dropdown:not(.p-disabled):hover
    border-color: $blue-main

.job
  .p-multiselect
    height: 3%
    width: 75%
    margin-left: 2vw
    font-size: calc(8px + 0.3vw)
    text-align: start
    border-radius: 5px

  .p-multiselect:not(.p-disabled):hover
    border-color: $blue-main

.p-calendar .p-inputtext
  height: 30px

.document .p-calendar .p-inputtext
  height: 20px

.filter-calendar.p-calendar .p-inputtext
  height: 27px
  font-size: 12px
  width: 144px

.filter-input
  height: 27px
  width: 144px
  font-size: 12px
  border: 1px solid $gray-9
  border-radius: 0.375rem
  margin-right: 2px
  &:focus-visible
    outline: 0

.filter-multiselect
  height: 27px
  width: 144px
  font-size: 12px
  border: 1px solid $gray-9
  border-radius: 0.375rem

  &.p-multiselect .p-multiselect-label
    margin-top: -4px

  &.p-multiselect:not(.p-disabled).p-focus
    box-shadow: none
    border: 0.5px solid #7E7E7E

  &.p-multiselect:not(.p-disabled):hover
    border: 0.5px solid #7E7E7E

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext
    height: 24px

  .p-multiselect-filter-icon
    top: 57%

  .pi
    font-size: 10px

  .p-multiselect-panel .p-multiselect-items
    font-size: 12px

.p-dropdown-panel
  .p-dropdown-filter
    height: 24px

.dialog__content-single
  .p-dropdown
    height: 30px
    min-width: 100%

  .p-calendar
    display: block
    .p-inputtext
      min-width: 100%
      border: 1px solid $gray-9
      border-radius: 0.375rem
      padding: 0.375rem 0.75rem
      height: 32px

.p-checkbox, .p-radiobutton
  scale: 80%

.content__box-emails-input
  .p-chips
    width: 100%
    max-height: 150px
  .p-chips .p-chips-multiple-container
    width: 100%
    max-height: 150px
    overflow-y: auto

.p-scroller
  height: 100% !important

.p-image-preview-container
  height: 100%

.content__users-table-body-row-dropdown
  margin: auto 0
  .p-cascadeselect
    width: 100%
    height: 32px
  .p-cascadeselect .p-cascadeselect-label
    padding: 0.4rem 0.5rem

.custom-autocomplete-panel
  font-size: 12px
  .p-button.p-button-icon-only
    width: 1.5rem !important
    height: 1.4rem !important
  .p-autocomplete-dd .p-autocomplete-dropdown
    width: 1.5rem !important
    height: 1.4rem !important
  .pi-times:before
    position: absolute
    right: -18px
    top: -4px
  .p-autocomplete-loader
    margin-top: -0.7rem

.job-autocomplete
  .p-button.p-button-icon-only
    height: 1.45rem !important
  .p-autocomplete-dd .p-autocomplete-dropdown
    height: 1.45rem !important

// this is so the address suggestions don't get hidden behind the dialog footer
@media screen and ( min-height: 800px )
  .job-dialog
    overflow-y: visible !important

.p-breadcrumb
  border: none
  font-weight: 600
  font-size: 18px
  align-items: center
  padding: 0
// this is so the address suggestions don't get hidden behind the dialog footer
@media screen and ( min-height: 800px )
  .job-dialog
    overflow-y: visible !important
