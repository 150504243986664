@import 'bootstrap/scss/bootstrap'
@import 'bootstrap-icons/font/bootstrap-icons.css'
@import "primeng/resources/primeng.min.css"
@import "primeng/resources/themes/saga-blue/theme.css"
@import "primeicons/primeicons.css"

@import 'assets/styles/variables'
@import 'assets/styles/textfield'
@import 'assets/styles/primeng'
@import 'assets/styles/ag-grid'

@import "~ag-grid-community/styles//ag-grid.css"
// @import "~ag-grid-community/styles//ag-theme-alpine.css"
@import "~ag-grid-community/styles//ag-theme-balham.css"

@font-face
  font-family: "Open Sans"
  src: url("/assets/fonts/OpenSans-Regular.ttf") format("truetype")
  font-weight: 400

@font-face
  font-family: "HelveticaNeue"
  src: url("/assets/fonts/HelveticaNeue.ttf") format("truetype")
  font-weight: 400

*
  font-family: Helvetica, Open Sans ,Roboto

html
  font-size: 14px

.pi
  font-family: 'primeicons'!important

.text-azure
  color: #69DED2

.icon-green
  width: 20px
  height: 20px
  background: $green-0
  text-align: center
  margin-right: 8px
  color: $green-1
  display: flex
  align-items: center
  justify-content: center

.dialog-supplier
  &__content
    display: flex
    flex-direction: column
    gap: 14px

    &-action
      display: flex
      justify-content: flex-end
      padding-top: 10px

      &-button
        border: none
        padding: 8px 40px
        background-color: $blue-main
        color: white
        font-weight: 600
        border-radius: 5px
        margin-top: 20px
        &:hover
          background-color: $blue-hover

.warning
  color: $warning-color
  &-text
    color: $warning-color
    margin-top: 5px
    margin-bottom: 0
    padding-left: 12px

.orders-items
  scrollbar-width: thin

table, .menu
  ::-webkit-scrollbar
    height: 4px
    width: 4px

::-webkit-scrollbar
  height: 8px
  width: 8px
  opacity: 0.6

::-webkit-scrollbar-track
  background: $gray-1

::-webkit-scrollbar-thumb
  background: #b6b4b4
  border-radius: 1px

::-webkit-scrollbar-thumb:hover
  background: #a5a4a4

.ng2-pdf-viewer-container
  overflow: hidden

.confirm-header
  display: flex
  flex-direction: row
  gap: 8px
  font-size: 18px
  color: $gray-5
  font-weight: 700
  margin: 40px 80px -15px 15px

.confirm-close
  color: #b9b7b7
  background-color: $white
  border: 0
  font-size: 20px
  margin-top: -50px
  margin-left: 10px
  border-radius: 50px
  width: 30px
  height: 30px
  &:hover
    background-color: $gray-1

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

::-webkit-input-placeholder
  color: $gray-9

.loader-center
  position: absolute
  top: 49%
  left: 48%
  z-index: 1

.geoapify-autocomplete-input
  width: 100%
  height: 32px

.geoapify-autocomplete-items
  font-size: 0.7rem
  padding: 8px
  z-index: 999

.geoapify-autocomplete-items .secondary-part
  font-size: 0.7rem

.column-header-content
  display: flex
  justify-content: flex-start
  align-items: center
  width: 100%

.bi-lightning
  font-size: 10px
  position: relative
  margin-left: 5px
  bottom: 6px
  color: gray

.bi-question-circle
  font-size: 10px
  position: relative
  margin-left: 8px
  bottom: 6px
  color: gray

.icon-container
  position: relative
  display: inline-flex
  align-items: center


.status-chart-link
  position: relative
  display: inline-block

.status-chart-link:hover .custom-tooltip
  display: block


.custom-scrollbar-close
  -webkit-overflow-scrolling: touch
  scrollbar-width: none
  -ms-overflow-style: none
  &::-webkit-scrollbar
    display: none

.custom-scrollbar
  &::-webkit-scrollbar
    overflow: visible
    width: 8px

  &::-webkit-scrollbar-corner
    background: transparent

  &::-webkit-scrollbar-thumb
    border-style: solid
    border-color: transparent
    background-color: #dadce0
    border-radius: 8px
    min-height: 28px
    &:hover
      background-color: #b0b0b0

  &::-webkit-scrollbar-track
    box-shadow: none
    background-clip: padding-box
    background: #fff
    border: solid transparent
    border-width: 0 0 0 4px
    margin: 0 4px
    &:hover
      background-color: rgba(0, 0, 0, .05)
      background: #fff
      box-shadow: none

.dropdown-menu.scrollable-menu.mention-menu
  border-radius: 8px
  box-shadow: 0 2px 6px 2px rgba(60,64,67,.15)


.lightning-tooltip
  display: block
  position: fixed
  background-color: #f9f9f9
  color: #333
  text-align: center
  z-index: 1000 !important
  padding: 8px 12px
  border: 1px solid #ddd
  border-radius: 4px
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
  bottom: 100%
  transform: translate(-25%, -10px)
  max-width: 230px
  word-wrap: break-word
  font-weight: 500
  font-size: 12px
  height: 70px

  &::after
    content: ""
    position: fixed
    margin-left: -5px
    border-width: 5px
    top: 0px
    left: 0px
    border-style: solid
    border-color: transparent transparent transparent transparent
    bottom: -10px